import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";

import storage from "redux-persist/lib/storage";

import auth from "./auth";
import main from "./main";
import throttle from "./throttle";
import hash from "./hash";
import modal from "./modal";

const rootPersistConfig = {
  key: "root",
  storage,
  whitelist: [""],
};

const authPersistConfig = {
  key: "auth",
  storage: storage,
  blacklist: ["err_code"],
};

const mainPersistConfig = {
  key: "main",
  storage: storage,
  // Ex: whitelist: ["login", "history"]
  // Only login and history will be persisted
  whitelist: [""],
};

const throttlePersistConfig = {
  key: "throttle",
  storage: storage,
  blacklist: [""],
};

const hashPersistConfig = {
  key: "hash",
  storage: storage,
  blacklist: ["temp_hash"],
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, auth),
  main: persistReducer(mainPersistConfig, main),
  throttle: persistReducer(throttlePersistConfig, throttle),
  hash: persistReducer(hashPersistConfig, hash),
  modal,
});

export default persistReducer(rootPersistConfig, rootReducer);
