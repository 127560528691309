export const toggle_modal = (modal, toggle, data_modal) => ({
  type: "TOGGLE_MODAL",
  modal,
  toggle,
  data_modal,
});

export const set_msg = (title, msg) => ({
  type: "SET_MSG",
  title,
  msg,
});
