import React from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { toggle_modal } from "redux/actions/modal";
// import { Redirect } from "react-router";
// import { Link, NavLink } from "react-router-dom";

const Error = (props) => {
  const dispatch = useDispatch();
  const { error } = props.modal;
  const { err_msg, tr } = useSelector((state) => state.main);

  const errCode = {
    server_err: "server error",
    invalid_action: "Invalid Action",
    too_many_request: "Please wait a moment",
  };

  const generateMsg = (errCode) => {
    switch (errCode) {
      case "no_jwt_token":
        return "Please Login";
      case "Invalid action":
        return "Invalid Action";
      case "not_logged_in":
        return "Please Login";
      case "m":
        return "Maintenance";
      case "0":
        return "Missing User Data";
      case "1":
        return "Error GOP";
      case "2":
        return "Missing open_id";
      case "3":
        return "Missing data";
      case "4":
        return "Missing Profile";
      case "41":
        return "Error Get GOP Data";
      case "invalid_param":
        return "Please refresh and try again";
      case "no_token":
        return "Please Login";
      case "api_error":
        return "Something Error";
      case "event_restricted":
        return "Event is undergoing maintenance";
      case "event_maintenance":
        return "Event is under maintenance";
      case "event_not_started":
        return "Event has not started";
      case "event_closed":
        return "Event ended";
      default:
        return errCode;
    }
  };

  return (
    <Modal
      open={error}
      focusTrapped={false}
      onClose={() => dispatch(toggle_modal("error", false))}
      center
      showCloseIcon={true}
      closeOnOverlayClick={false}
      classNames={{
        overlay: "overlay",
        modal: `modal error`,
      }}
    >
      <div className="content">
        <h1>
          {(tr && tr[err_msg]) ||
            errCode[err_msg] ||
            generateMsg(err_msg) ||
            "Please refresh your browser"}
        </h1>
      </div>
    </Modal>
  );
};

export default Error;
