import axios from "axios";
import { err_msg, getUrlParam } from "helper";
import { set_msg, toggle_modal } from "./modal";
// import { set_hash } from './auth';
// import { put_hash } from './hash';

const cdn = axios.create({
  baseURL: `https://cdngarenanow-a.akamaihd.net/devid/${process.env.REACT_APP_LOCALIZE_NAME}/`,
  withCredentials: false,
});

// const common = axios.create({
//   baseURL: `https://cdngarenanow-a.akamaihd.net/webid/TranslateCommon/`,
//   withCredentials: false,
// });

export const toggle_popup = (bool, tipe) => ({
  type: "TOGGLE_POPUP",
  bool,
  tipe,
});

export const toggle_loader = (data) => ({
  type: "TOGGLE_LOADER",
  data: data,
});

export const toggle_loader_hash = (data) => ({
  type: "TOGGLE_LOADER_HASH",
  data: data,
});

export const put_data = (key, data) => ({
  type: "PUT_DATA",
  key,
  data,
});

const ls = window.localStorage;

/**
 *
 * Example of how to call API
 *
 */
export const login = () => {
  return (dispatch, getState) => {
    // UNCOMMENT & ADD DEFAULT LANG AS PARAM ex: 'EN'
    // dispatch(get_translate('EN'))
    dispatch(toggle_loader(true));

    axios
      .get(process.env.REACT_APP_API_ENDPOINT)
      .then((resp) => {
        dispatch(put_data("login", resp.data));

        /*
					If you want to set the data as HASH, 
					replace the key below with a hash key and 
					don't forget to include the state key into 
					whitelist in reducers/index.js
				*/
        // const key = 'login';
        // const { temp_hash } = getState().hash;
        // if (temp_hash[key].type === 'set_hash') {
        //   dispatch(set_hash(key));
        // } else {
        //   dispatch(put_hash(key, temp_hash[key].data));
        // }
      })
      .catch((err) => {
        dispatch(catch_error(err_msg(err)));
      })
      .then(() => {
        dispatch(toggle_loader(false));
      });
  };
};

export const set_token = () => {
  return (dispatch) => {
    const token = getUrlParam("access_token");
    // const language = getUrlParam("lang");
    // const { decodedToken, isExpired } = useJwt(token);
    // console.log(decodedToken);
    // get_translate(language)

    if (getUrlParam("err")) {
      if (ls.getItem("lang") !== undefined) {
        dispatch(get_translate(ls.getItem("lang").toString().toUpperCase()));
      }
      dispatch(catch_error(getUrlParam("err")));
    } else {
      if (token) {
        ls.setItem("token", token);
        axios.defaults.headers[process.env.REACT_APP_TOKEN_HEADER_NAME] = token;
        // removeParamUrl();
        // window.location = process.env.REACT_APP_BASE_URL;
      }
      dispatch(check_login());
    }
  };
};

export const check_login = () => {
  return (dispatch) => {
    dispatch(toggle_loader(true));

    axios
      .get(`oauth/check_login/`)
      .then((resp) => {
        dispatch(put_data("user", resp.data.user));
        dispatch(get_info());
      })
      .catch((err) => {
        dispatch(catch_error(err_msg(err)));
        // dispatch(get_info());
      })
      .then(() => {
        dispatch(toggle_loader(false));
      });
  };
};

export const get_info = () => {
  return (dispatch) => {
    dispatch(toggle_loader(true));
    axios
      .get(`api/info/`)
      .then((resp) => {
        dispatch(put_data("info", resp.data.data));
      })
      .catch((err) => {
        dispatch(catch_error(err_msg(err)));
      })
      .then(() => {
        dispatch(toggle_loader(false));
      });
    // const temp = {
    //   data: {
    //     otp_expired: null,
    //     has_verify: false,
    //   },
    // };
    // dispatch(put_data("info", temp.data));
  };
};

export const get_otp = (payload) => {
  return (dispatch) => {
    dispatch(toggle_loader(true));

    axios
      .post(`api/generate/`, payload)
      .then((resp) => {
        dispatch(put_data("generate", resp.data.data));
        dispatch(get_info());
      })
      .catch((err) => {
        dispatch(catch_error(err_msg(err)));
      })
      .then(() => {
        dispatch(toggle_loader(false));
      });
  };
};

export const cancel = () => {
  return (dispatch) => {
    dispatch(toggle_loader(true));

    axios
      .post(`api/cancel/`)
      .then((resp) => {        
        dispatch(get_info());
      })
      .catch((err) => {
        dispatch(catch_error(err_msg(err)));
      })
      .then(() => {
        dispatch(toggle_loader(false));
      });
  };
};

export const verify_otp = (otp) => {
  return (dispatch) => {
    dispatch(toggle_loader(true));
    axios
      .post(`api/verify/?otp=${parseInt(otp)}`, {
        token: ls.token,
        otp: parseInt(otp),
      })
      .then((resp) => {
        dispatch(put_data("verify", resp.data.data));
        dispatch(get_info());
      })
      .catch((err) => {
        console.log(err.msg);
        dispatch(catch_error(err_msg(err)));
      })
      .then(() => {
        dispatch(toggle_loader(false));
      });
    // const temp = {
    //   data: {
    //     otp_expired: null,
    //     has_verify: false,
    //   },
    // };
    // dispatch(put_data("info", temp.data));
  };
};

/**
 *
 * Example of how to implement multi language
 *
 */
// export const get_translate = (default_lang) => {
//   return (dispatch) => {
//     let language = getLsObject("param")?.lang.toUpperCase() || default_lang;

//     const main_localize = cdn.get(`${language}.json?${Math.random() * 10000}`);
//     const common_localize = common.get(
//       `${language}.json?${Math.floor(Math.random() * 10000)}`
//     );

//     axios
//       .all([main_localize])
//       .then((resp) => {
//         const json = {
//           ...resp[0].data.data,
//         };
//         dispatch(put_data("lang_json", json));
//       })
//       .catch((err) => {});
//   };
// };
// export const get_translate = (lang) => {
//   return (dispatch) => {
//     const paramLang = ls.getItem("lang");

//     if (paramLang) {
//       if (paramLang === "ind") {
//         ls.setItem("lang", "ID");
//       } else {
//         ls.setItem("lang", paramLang);
//       }
//     } else {
//       if (!ls.getItem("lang")) {
//         ls.setItem("lang", "EN");
//       }
//     }

//     const language = lang || paramLang || ls.getItem("lang");
//     const listLang = ['en', 'ar'];
//     const localize = listLang.includes(lang) ? lang : 'ar';

//     dispatch(put_data("lang", language));

//     // const language = paramLang ||default_lang|| "EN";

//     cdn
//       .get(`${language.toUpperCase()}.json?${Math.random() * 10000}`)
//       .then((resp) => {
//         dispatch(put_data("tr", resp.data));
//       })
//       .catch((err) => {
//         dispatch(catch_error(err_msg(err)));
//       });
//   };
// };

export const get_translate = (lang) => {
  return (dispatch) => {
    const listLang = ["id", "ind", "ar", "en", "ID", "IND", "AR", "EN"];
    const language = listLang.includes(lang) ? lang : "en";

    dispatch(put_data("lang", language));

    cdn
      .get(`${language.toUpperCase()}.json?${Math.random() * 10000}`)
      .then((resp) => {
        dispatch(put_data("tr", resp.data));
      })
      .catch((err) => {
        dispatch(catch_error(err_msg(err)));
      });
  };
};

/**
 *
 * Example of how to catch error if not logged in
 *
 */
export const catch_error = (err_msg) => {
  return (dispatch, getState) => {
    if (err_msg === "not_logged_in") {
      const { auth } = getState();
      window.location.href = `${`${process.env.REACT_APP_BASE_URL}?access_token=${auth.param.access_token}&account_id=${auth.param.account_id}&nickname=${auth.param.nickname}&region=${auth.param.region}`}`;
    } else {
      if (err_msg) {
        dispatch(set_msg("err_msg", err_msg));
        dispatch(put_data("err_msg", err_msg));
      }
      dispatch(toggle_modal("error", true));
      dispatch(toggle_loader(false));
    }

    if (err_msg === "invalid_region") {
      localStorage.clear();
    }
  };
};
