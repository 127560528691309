import axios from "axios";
import * as main from "./main";
// import { throttle } from "./throttle";
import { get_value_hash, put_hash, put_temp_hash } from "./hash";
import { err_msg, getUrlParam } from "helper";
import { toggle_modal } from "./modal";
// import { toggle_modal } from "./modal";

const ls = window.localStorage;

export const set_param = (data) => ({
  type: "SET_PARAM",
  data,
});

export const set_callback = (data) => ({
  type: "SET_CALLBACK",
  data,
});

export const set_url_code = (data) => ({
  type: "SET_URL_CODE",
  data,
});

export const set_token = () => {
  return (dispatch, getState) => {
    const param = {
      access_token: getUrlParam("access_token") || "",
      account_id: getUrlParam("account_id") || "",
      region: getUrlParam("region") || "",
      nickname: getUrlParam("nickname") || "",
      lang: getUrlParam("lang") || "",
    };

    const { auth } = getState();

    const isDifferentAccount = () => {
      if (param.account_id) {
        if (param.account_id !== auth.param.account_id) {
          return true;
        }
        return false;
      }
    };

    if (param.lang) {
      if (param.lang === "ind") {
        ls.setItem("lang", "ID");
      } else {
        ls.setItem("lang", param.lang);
      }
      dispatch(main.get_translate(ls.getItem("lang")));
    } else {
      dispatch(
        main.get_translate(
          (auth?.param?.lang === "ind" ? "ID" : auth?.param.lang) || "EN"
        )
      );
    }

    if (isDifferentAccount()) {
      ls.clear();
      dispatch(set_callback(window.location.href));
      dispatch(set_param(param));
      dispatch(get_jwt(false));
    } else {
      if (param.access_token && param.account_id) {
        dispatch(set_callback(window.location.href));
        dispatch(set_param(param));
        dispatch(get_jwt());
      } else {
        if (auth.param.access_token && auth.param.account_id) {
          // // Throttling and hash login example
          // if (!dispatch(throttle('login', 5000))) {
          //   dispatch(get_hash('login'));
          // }
          dispatch(main.check_login());
        } else {
          // Encrypt url callback from banner
          dispatch(set_callback(window.location.href));
          // dispatch(encrypt_callback());
          // Toggle popup not logged in
          dispatch(toggle_modal("error", true));
          dispatch(main.put_data("err_msg", "not_logged_in"));
        }
      }
    }
    // if (ls.getItem("token")) {
    //   dispatch(main.check_login());
    // } else {
    //   dispatch(main.put_data("err_msg", "not_logged_in"));
    //   dispatch(toggle_modal("error", true));
    // }
  };
};

export const get_jwt = (throttling = true) => {
  return (dispatch, getState) => {
    // Throttling /oauth/callback/ 43200000s/12 hours
    // if (dispatch(thrott("jwt_token", 43200000)) && throttling) {
    //   window.location.href = `${process.env.REACT_APP_BASE_URL}`;
    //   return;
    // }

    const { auth } = getState();
    dispatch(main.toggle_loader(true));

    axios
      .get(
        `oauth/callback/?access_token=${auth.param.access_token}&account_id=${auth.param.account_id}`
      )
      .then((resp) => {
        ls.setItem("token", resp.data.token);
        // dispatch(main.check_login());
        window.location.href = `${process.env.REACT_APP_BASE_URL}`;
      })
      .catch((err) => {
        dispatch(main.catch_error(err_msg(err)));
        // Send error to analytics
        // analyticsAPICallErrorReporting(err);
      })
      .then(() => dispatch(main.toggle_loader(false)));
  };
};

const setKey = (key, param) => {
  // Prefix hash
  const baseKey = "event";
  switch (key) {
    case "login":
      return `${baseKey}:api:check_login:${param.account_id}`;
    default:
      return "";
  }
};

// HASH
const hash = axios.create({
  baseURL: process.env.REACT_APP_API_HASH,
});

export const get_hash = (key) => {
  return (dispatch, getState) => {
    const fetch_data = (fetch = true) => {
      switch (key) {
        case "login":
          if (fetch) {
            // Fetch new data from endpoint that match the key
            // ex:	dispatch(main.login());
          } else {
          }
          break;
        default:
          break;
      }
    };

    const { param } = getState().auth;
    const payload = JSON.stringify({
      key: setKey(key, param),
    });

    dispatch(main.toggle_loader_hash(true));
    hash
      .post(`api/get_hash`, payload)
      .then((resp) => {
        if (resp.data.value === "Not Found") {
          dispatch(put_temp_hash(key, resp.data, "set_hash"));
          fetch_data(true);
        } else {
          if (dispatch(get_value_hash(key)) === resp.data.value) {
            fetch_data(false);
          } else {
            dispatch(put_temp_hash(key, resp.data, "put_hash"));
            fetch_data(true);
          }
        }
      })
      .catch((err) => {
        dispatch(main.catch_error(err_msg(err)));
      })
      .then(() => {
        dispatch(main.toggle_loader_hash(false));
      });
  };
};

export const set_hash = (key) => {
  return (dispatch, getState) => {
    const { param } = getState().auth;
    const payload = JSON.stringify({
      key: setKey(key, param),
      timeout: 43200000,
    });

    hash
      .post("api/set_hash", payload)
      .then((resp) => {
        dispatch(put_hash(key, resp.data));
      })
      .catch((err) => {
        dispatch(main.catch_error(err_msg(err)));
      });
  };
};

const encrypt = axios.create({
  baseURL: "https://toolbox-go.garena.com/paste/",
});

export const encrypt_callback = () => {
  return (dispatch, getState) => {
    const { callback } = getState().auth;

    encrypt
      .post("/", { text: callback })
      .then((resp) => {
        dispatch(set_url_code(resp.data.data.code));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// const dlt = axios.create({
// 	baseURL: process.env.REACT_APP_API_DLT,
// })

// const get_dlt = () => {
// 	const param = getLsObject("param")
// 	const data = {
// 		account: param.accountId,
// 		region: param.region,
// 		event: "event.game.garena.com",
// 	}
// 	const payload = {
// 		payload: encryptPayload(data, "dlt"),
// 	}

// 	return (dispatch) => {
// 		dlt
// 			.post(`api/checkin/`, payload)
// 			.then((resp) => {
// 				console.log(resp.data)
// 			})
// 			.catch((err) => {
// 				console.log(err)
// 			})
// 			.then(() => {
// 				window.location.href = `${process.env.REACT_APP_BASE_URL}`
// 			})
// 	}
// }

// const pdc = axios.create({
// 	baseURL: process.env.REACT_APP_API_PDC,
// })

// const get_pdc = (key) => {
// 	const payload = {
// 		key: "test",
// 	}

// 	return (dispatch) => {
// 		pdc
// 			.post("api/get_value", payload)
// 			.then((resp) => {
// 				setPdc(key, resp.data)
// 			})
// 			.catch((err) => {
// 				console.log(err)
// 			})
// 	}
// }

// export const set_pdc = (key, value) => {
// 	const payload = {
// 		key: key,
// 		value: value,
// 		timeout: 3600,
// 	}

// 	return (dispatch) => {
// 		pdc
// 			.post("api/set_value", payload)
// 			.then((resp) => {
// 				console.log(resp.data)
// 			})
// 			.catch((err) => {
// 				console.log(err)
// 			})
// 	}
// }
