import React from "react";
import { useSelector } from "react-redux";
import Error from "./Modal.Error";
import Confirm from "./Modal.Confirm";

const Modal = () => {
    const state = useSelector((state) => state);

    return (
        <>
            <Confirm {...state} />
            <Error {...state} />
        </>
    );
};

export default Modal;
