import React, { lazy, Suspense } from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";

// Component
import ErrorBoundary from "ErrorBoundary";
import ViewportRestrict from "helper/component/viewport";
import Loader from "helper/component/loader";
import NotFound from "helper/component/404";
import LoadingScreen from "helper/component/loadingscreen";
import { componentLoader, fixView } from "helper";

// REDUX
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as mainActions from "redux/actions/main";
import { useEffect } from "react";

import "./../assets/scss/index.scss";
import { set_token } from "redux/actions/auth";
import Modal from "components/Modal";

// Pages
const Home = lazy(() => componentLoader(() => import("pages/Home")));

export const Main = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(set_token());
    fixView();
    // dispatch(mainActions.check_login());
    //eslint-disable-next-line
  }, []);

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <div id="Main" className="main-panel">
        <ErrorBoundary>
          <Suspense fallback={<LoadingScreen />}>
            <ViewportRestrict display={false} type="landscape" />
            <Loader loader={props?.main?.loader} />
            <div className="content-container">
              <Switch>
                <Route exact path="/" component={Home} />
                {/* <Route exact path="/otp" component={OTP} />
                <Route exact path="/account" component={Progress} /> */}
                <Route component={NotFound} />
              </Switch>
            </div>
            <Modal />
          </Suspense>
        </ErrorBoundary>
      </div>
    </Router>
  );
};

const mapStateToProps = (state) => ({
  main: state.main,
});

const mapDispatchToProps = (dispatch) => ({
  actionsMain: bindActionCreators(mainActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
