import React from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { cancel } from "redux/actions/main";
import { toggle_modal } from "redux/actions/modal";

const Confirm = (props) => {
    const { confirm } = props.modal;
    const { tr } = props.main;
    const dispatch = useDispatch();

    const handleCancel = () => {
        dispatch(cancel());
        dispatch(toggle_modal("confirm", false));
    };

    return (
        <Modal
            open={confirm}
            focusTrapped={false}
            onClose={() => dispatch(toggle_modal("confirm", false))}
            center
            showCloseIcon={true}
            closeOnOverlayClick={false}
            classNames={{
                overlay: "overlay",
                modal: `modal confirm`,
            }}
        >
            <div className="content">
                <p
                    dangerouslySetInnerHTML={{
                        __html: tr?.txt_confirm_message,
                    }}
                />
                <div className="btn-group">
                    <button onClick={handleCancel}>Yes</button>
                    <button
                        onClick={() => dispatch(toggle_modal("confirm", false))}
                    >
                        No
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default Confirm;
